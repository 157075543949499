import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import csilGlass from "../images/csilGlass2.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import FirstVisitPopup from "./popup"; // Add this import

import { faAt } from "@fortawesome/free-solid-svg-icons";

function Toplandingpage() {
  const [isFirstVisit, setIsFirstVisit] = useState(false);
  const [onClose, setOnClose] = useState(false);
  useEffect(() => {
    // Check if the user has visited before by checking localStorage.
    const visited = localStorage.getItem('visited');

    // If not visited, show the popup and set the localStorage flag.
    if (!visited) {
      setIsFirstVisit(true);
      localStorage.setItem('visited', 'true');
    }
  }, []); // Run only once when the component mounts.

  return (
    <div className="titleOutDiv">
      {(isFirstVisit && !onClose)? <FirstVisitPopup setOnClose = {setOnClose}/>
      : null
    }
      <div className="titleDiv">
        <div
          className="labname"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          Computer Science Instructional Laboratory
          <div className="learnmore">
            <Link to="/about">
              LEARN MORE <span style={{ fontWeight: 900 }}> → </span>
            </Link>
          </div>
        </div>

        {/* <br /> */}
        <div className="homeInfo">
          <div className="description">
            Welcome to the University of Chicago Computer Science Instructional
            Laboratory (CSIL)!
          </div>

          <div className="wanttotalk">
            <Link to="/contact">
              <span> WANT TO TALK? </span>
            </Link>
          </div>

          <div className="socialBarRow">
            <div className="fb">
              <a
                href="https://www.facebook.com/uchicagocsil/"
                target="_blank"
                rel="noopener noreferrer"
                className="socialBarLink"
              >
                <FontAwesomeIcon className="socialIcon" icon={faFacebook} />
              </a>
            </div>
            <div className="insta">
              <a
                href="https://www.instagram.com/uchicagocsil/?hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon className="socialIcon" icon={faInstagram} />
              </a>
            </div>
            <div className="twit">
              <a
                href="https://twitter.com/UChicagoCSIL"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon className="socialIcon" icon={faXTwitter} />
              </a>
            </div>
            <div className="atem">
              <a
                href="mailto: csil@cs.uchicago.edu"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon className="socialIcon" icon={faAt} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

class Home extends React.Component {
  render() {
    return (
      <div
        className="main"
        style={{
          overflowY: this.props.disableScroll ? "hidden" : "auto",
          position: this.props.disableScroll ? "fixed" : "relative",
          animation: "none",
        }}
      >
        {/* component from above */}
        <Toplandingpage />
        {/* Used to scene3.js, but it was moved into home.jsx */}
        <div className="dkBlueBackground textCenter">
          <div className="textCenter ">
            <div className="homeWidth70percent verticalPaddingL">
              <h2>Serving UChicago For Over 35 Years</h2>

              <p className="whiteText  montserrat verticalPaddingS">
                Since 1985, CSIL has provided students, faculty, and university
                affiliates with a variety of computing services, from hardware
                support to computer software to minicourse lessons. We are
                entirely student-run, with the exception of our director Cosmos!
              </p>
              <p>
                <Link to="/about" onClick={() => window.scrollTo(0, 0)}>
                  <button className="montserrat brightRedButton">
                    ABOUT CSIL
                  </button>
                </Link>
              </p>
            </div>{" "}
          </div>
        </div>
        {/* quarter hours remember to change */}
        <div
          className="genericContentContainer"
          style={{ flexDirection: "column", alignItems: "center" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h2>Lab Hours</h2>
            <p className="bold">Winter Quarter ❄️</p>
            <p>
              Weekdays - 10am to 10pm <br></br>
              Weekends - 12pm to 10pm <br></br>
            </p>
          </div>
        </div>{" "}
        {/* rendering the four tiles*/}
        <div
          className="cardGrid twoByTwo"
          style={{ width: "80%", alignSelf: "center" }}
        >
          {/* individual tiles */}
          <div className="biCard">
            <div className="cardIn">
              <h2>Lab Reservation</h2>
              <p className="montserrat whiteText">
                Our five labs are located on the first floor of Crerar. Each lab
                holds up to 20 students and comes with current hardware and
                software for macOS and Ubuntu Linux, along with audio/video
                equipment.{" "}
                <Link
                  to="/contact"
                  onClick={() => window.scrollTo(0, 0)}
                  className="montserrat linkMarginBottom bold  brightRedLink"
                >
                  Contact us
                </Link>{" "}
                to reserve a lab or view our{" "}
                <Link
                  to="/labschedule"
                  onClick={() => window.scrollTo(0, 0)}
                  className="montserrat linkMarginBottom bold brightRedLink"
                >
                  Lab Schedule
                </Link>
                .
              </p>
            </div>
          </div>
          {/* individual tiles */}
          <div className="biCard">
            <div className="cardIn">
              <h2>Workshops</h2>
              <p className="montserrat whiteText">
                We offer free minicourse sessions each quarter, taught by
                UChicago students. Learn about systems, software, and more in a
                relaxed environment. Reservation is not required. Check out our{" "}
                <Link
                  to="/minicourses"
                  onClick={() => window.scrollTo(0, 0)}
                  className="montserrat linkMarginBottom bold brightRedLink"
                >
                  minicourses page
                </Link>{" "}
                for more.
              </p>
            </div>
          </div>

          <div className="biCard">
            <div className="cardIn">
              <h2>User Support</h2>
              <p className="montserrat whiteText">
                CSIL has current hardware and software for macOS and Ubuntu
                Linux, along with hardware, scanners, and video projection
                equipment in the labs. Check out our list of our resources on
                our{" "}
                <Link
                  to="/software"
                  onClick={() => window.scrollTo(0, 0)}
                  className="montserrat linkMarginBottom bold brightRedLink"
                >
                  software page
                </Link>{" "}
                .
              </p>
            </div>
          </div>
          {/* individual tiles */}
          <div className="biCard">
            <div className="cardIn">
              <h2>Borrow Resources</h2>
              <p className="montserrat whiteText">
                From chargers to headphones to adapters to mice, and more, CSIL
                offers a{" "}
                <Link
                  to="/hardware"
                  onClick={() => window.scrollTo(0, 0)}
                  className="montserrat linkMarginBottom bold brightRedLink"
                >
                  wide variety of equipment
                </Link>{" "}
                that you can check out for free. Visit the CSIL tutor desk
                during oppening hours to check out a resource.
              </p>
            </div>
          </div>
        </div>
        {/* final fold of the page */}
        <div className="genericContentContainer" style={{ width: "90%" }}>
          <div
            style={{
              width: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h2>Get in touch!</h2>
            <p>
              For CSIL-related questions, please contact{" "}
              <Link
                to="/staff"
                onClick={() => window.scrollTo(0, 0)}
                className="montserrat linkMarginBottom bold brightRedLink"
              >
                our staff
              </Link>{" "}
              instead of Cosmos. <br></br>You can find their contact info on the
              staff page. Don't be afraid to reach out!
            </p>
          </div>
          <div className="bi rowM ">
            <img
              src={csilGlass}
              alt="csilGlassImg"
              className="csilGlassImg imgShadow"
            />
          </div>
        </div>
      </div>
    );
  }
}
export default Home;
