import React from 'react';
import "./popup.css";
import { Link } from "react-router-dom";


const FirstVisitPopup = ({ setOnClose }) => {
  return (
    <div className="first-visit-popup">
      <div className="popup-content">
      <text>
      <i onClick={setOnClose} className="fa-regular fa-circle-xmark"></i>      </text>
      <div className = "popup-info">
        <h2>JOIN US!</h2>
        <p>We are hiring! Join the team</p>
        <Link to="/joinus">
        <button onClick={setOnClose} className="popup-close-btn">

              LEARN MORE <span style={{ fontWeight: 900 }}> → </span>
              </button>

        </Link>
        </div>
      </div>

    </div>
  );
};

export default FirstVisitPopup;