import React, { useState, useEffect } from 'react';
import './3Dpr.css';
import useWebSocket from "react-use-websocket";

const Schedule = () => {
  const [slots, setSlots] = useState([
    { id: 0, title: 'Calcifer', isAvailable: true, schedule: [], bgColor:"#f59e24" },
    { id: 1, title: 'Jiji', isAvailable: true, schedule: [], bgColor:"#2c3e50" },
    { id: 2, title: 'Mononoke', isAvailable: true, schedule: [], bgColor: "#64b1ff" },
    { id: 3, title: 'Totoro', isAvailable: true, schedule: [], bgColor: "#a0a57b" },
  ]);

  const [dayOffset, setDayOffset] = useState(0);
  const [ready, setReady] = useState(false);

  const WS_URL = "wss://website-node.ra.cs.uchicago.edu/";
  const { lastJsonMessage } = useWebSocket(WS_URL, {
    share: false,
    shouldReconnect: () => true,
  });

  useEffect(() => {
    if (lastJsonMessage) {
      setReady(true);
      const updatedSlots = [...slots];
      updatedSlots.forEach((slot, i) => {
        slot.isAvailable = lastJsonMessage[i].available;
        slot.schedule = lastJsonMessage[i].schedule;
      });
      setSlots(updatedSlots);
    }
  }, [lastJsonMessage]);

  const getDisplayDate = (offset) => {
    const date = new Date();
    date.setDate(date.getDate() + offset);
    return date;
  };

  const formatDate = (date) => {
    return date.toLocaleDateString(undefined, {
      month: 'numeric',
      day: 'numeric',
    });
  };

  const handlePreviousDay = () => setDayOffset(prev => Math.max(0, prev - 1));
  const handleNextDay = () => setDayOffset(prev => prev + 1);

  const processTimeBlocks = (schedule) => {
    const displayDate = getDisplayDate(dayOffset);
    const timelineStart = new Date(displayDate);
    timelineStart.setHours(10, 0, 0, 0);
    const timelineEnd = new Date(displayDate);
    timelineEnd.setHours(22, 0, 0, 0);
  
    const now = new Date();
  
    return schedule
      .map(entry => {
        const start = new Date(entry.start);
        const end = new Date(entry.end);
        const localStart = new Date(start.toLocaleString());
        const localEnd = new Date(end.toLocaleString());
  
        if (localEnd < timelineStart || localStart > timelineEnd) {
          return null;
        }
  
        const adjustedStart = localStart < timelineStart ? timelineStart : localStart;
        const adjustedEnd = localEnd > timelineEnd ? timelineEnd : localEnd;
  
        const totalHours = 12;
        const startOffset = (adjustedStart - timelineStart) / (1000 * 60 * 60);
        const duration = (adjustedEnd - adjustedStart) / (1000 * 60 * 60);
  
        const isCurrent = now >= adjustedStart && now <= adjustedEnd;
        let currentPosition = null;
  
        if (isCurrent) {
          currentPosition = ((now - adjustedStart) / (adjustedEnd - adjustedStart)) * 100;
        }
  
        return {
          left: (startOffset / totalHours) * 100,
          width: (duration / totalHours) * 100,
          startTime: adjustedStart.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' }),
          endTime: adjustedEnd.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' }),
          isCurrent,
          currentPosition
        };
      })
      .filter(entry => entry !== null && entry.width > 0);
  };

  const generateTimeTickers = () => {
    const ticks = [];
    const displayDate = getDisplayDate(dayOffset);
    for (let hour = 10; hour <= 22; hour++) {
      const time = new Date(displayDate);
      time.setHours(hour, 0, 0, 0);
      let formatted = time.toLocaleTimeString([], { hour: 'numeric', hour12:false});
      ticks.push(formatted);
    }

    return ticks;
  };

  return (
    <div className="main margins">
      <div className="title col" style = {{flexGrow:"0"}}>
        <h4>RESERVATIONS</h4>
        <h1>3D Printer Availability</h1>
      </div>

      <div className="dayNavigation">
        <h2>{formatDate(getDisplayDate(dayOffset))}</h2>

        <button onClick={handlePreviousDay} disabled={dayOffset === 0}>
        <i class="fa-solid fa-less-than"></i>

        </button>
        <button onClick={handleNextDay} disabled={dayOffset === 2}>
        <i class="fa-solid fa-greater-than"></i>
        </button>
      </div>

      <div className="scheduleContainer">
        {slots.map((slot) => {
          const timeBlocks = processTimeBlocks(slot.schedule);
          
          return (
            <div className="printerTimeCard" key={slot.id}>
              <div className="printerHeader">
                <h3>{slot.title}</h3>
                  <div className={(ready && (dayOffset < 1)? "show " : "hidden ") + "availabilityStatus"}>
                    <span style={{ fontSize: "1rem" }}>
                      {slot.isAvailable ? 'Available Now' : 'Occupied'}
                    </span>
                    <div className={`indicator ${slot.isAvailable ? 'available' : 'occupied'}`} />
                  </div>
              </div>
              <div className="printerSchedule">
                <div className="timeline">
                  {timeBlocks.length === 0 ? (
                    <div className="noReservations">No reservations</div>
                  ) : (
                    timeBlocks.map((block, i) => ( block.width>.5?
                      <div
                        key={i}
                        className="timeBlock"
                        style={{
                          background: slot.bgColor,
                          left: `${block.left}%`,
                          width: `${block.width}%`,
                        }}
                      >
                        <span className="timeLabel">
                          {block.startTime} -<br/>{block.endTime}
                        </span>
                        {block.isCurrent && (
                          <div
                            className="currentTimeIndicator"
                            style={{
                              left: `${block.currentPosition}%`,
                            }}
                          ></div>
                        )}
                      </div> : null
                    ))
                    
                  )}
                </div>
                <div className="timeLabels">
                  {generateTimeTickers().map((label, index) => (
                    <span className = {"timeTick"} key={index}>{label}</span>
                  ))}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Schedule;
