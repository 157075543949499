import React from 'react';
import './JoinUs.css';

function JoinUs() {
  return (
    <div className="main">
    <div className="join-us-main paddingBottom">
      <div className="join-us-header">
        <h4>JOIN US</h4>
        <h1>Where Tech, Teamwork, and Creativity Thrive!</h1>
      </div>

      <section className="join-us-section">
        <h2><b>🚀 What You Will Do @ CSIL</b></h2>
        <div className="grid-container">
          <div className="grid-item">
            <div>
            <i className="fas fa-code icon-accent"></i>
            <h3>Software & Systems</h3>
            </div>
            <p>Solve hardware issues, design software, and manage client-server interactions.</p>
          </div>
          <div className="grid-item">
            <div>
            <i className="fas fa-screwdriver-wrench icon-accent"></i>
            <h3>Build In House Tools</h3>
            </div>
            <p>Develop creative utilities and automation tools to enhance lab efficiency.</p>
          </div>
          <div className="grid-item">
            <div>

            <i className="fas fa-chalkboard icon-accent"></i>
            <h3>Teaching & Mentoring</h3>
            </div>

            <p>Lead short workshops on key topics like Linux, Git, Python, Machine Learning, Security, and Databases.</p>
          </div>
          <div className="grid-item">
            <div>

            <i className="fas fa-handshake-angle icon-accent"></i>
            <h3>User Support</h3>
            </div>

            <p> Provide friendly, hands-on assistance for macOS, Linux, and Windows
            workstations during your scheduled service-desk shifts.</p>
          </div>
          <div className="grid-item">
            <div>

            <i className="fas fa-server icon-accent"></i>
            <h3>Practice AI, Data Analytics, and DevOps</h3>
            </div>
            <p> Contribute to state-of-the-art research and service development that keeps CSIL on the cutting edge.</p>
          </div>
          <div className="grid-item">
            <div>

            <i className="fas fa-laptop icon-accent"></i>
            <h3>Manage Infrastructure</h3>
            </div>

            <p> Maintain and administer over 120 desktop computers and support 
            lab servers.</p>
          </div>
        </div>
      </section>

      <section className="join-us-section dark-bg">
        <h2><b>🔍 What We're Looking For</b></h2>
        <ul className="icon-list">
          <li><i className="fas fa-smile-beam"></i>Friendly problem-solver mindset.</li>
          <li><i className="fas fa-users"></i>Team player attitude.</li>
          <li><i className="fas fa-book"></i>Willingness to pick up new programming languages and systems.</li>
          <li><i className="fab fa-python"></i>Some experience with programming & tech – We use Python, JavaScript, Rust, C#, Go, PostgreSQL, React, bash, macOS, powershell, Docker, and more. (Of course, we don't expect you to know all of these!)</li>
          <li><i className="fas fa-calendar"></i>Plan to work ~10 hours/week on a fixed schedule and stay with CSIL for at least two years so you can build expertise and take on bigger projects.</li>
          <li><i className="fas fa-seedling"></i>As you gain experience and take on more responsibilities, you’ll have opportunities to earn more and advance within CSIL.</li>
          {/* Add other list items */}
        </ul>
      </section>

      <section className="join-us-section">
        <h2><b>💎 Why Join CSIL?</b></h2>
        <div className="card-container">
          <div className="benefit-card">
            <h3>Real-World Experience</h3>
            <p>Tangible projects that strengthen your resume</p>
          </div>
          <div className="benefit-card">
            <h3>A Creative & Supportive Environment</h3>
            <p>CSIL is a space where students experiment, build, and innovate.</p>
          </div>
          <div className="benefit-card">
            <h3>Launchpad for Growth</h3>
            <p>Our staff members land top-tier internships and research roles thanks to the skills they developed here.</p>
          </div>
          <div style ={{"alignItems":"center", "display":"flex", "flex-direction":"column"}}>
          <h2> <b>And most importantly...</b></h2>
          <div className="benefit-card">
            <h3>A Fun, Collaborative Culture</h3>
            <p>Work with like-minded peers in a space where tech meets creativity!</p>
          </div>
          </div>
        </div>
      </section>

      <section className="join-us-section apply-section">
        <h2>📩 Apply Now!</h2>
        <div className="apply-content">
          <p>Email Our Director, Cosmos Boekell, at:</p>
          <a href="mailto:cboekell@uchicago.edu" className="apply-button">
            cboekell@uchicago.edu
          </a>
          <p>Include your <b>resume</b>, <b>technical experience</b>, and <b>why</b> you're interested!</p>
        </div>
      </section>
    </div>
    </div>
  );
}

export default JoinUs;